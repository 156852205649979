import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Header from "../components/header";
import Menu from "../components/Menu";
import Footer from "../components/footer";
import MixPostGrid from "../components/mix-post-grid";
import SharePopupModel from "../components/share-popup-model";
import ReactHtmlParser from "react-html-parser";
import {Helmet} from "react-helmet";
import ReadMoreBtn from "../components/read-more-btn";
import images from "../components/images";
import globe from "../globals"

export default function Category({ pageContext }) {
    const { category } = pageContext;

    let q = useStaticQuery(graphql`
  query {
      site {
          siteMetadata {
            title
            siteUrl
          }
        }
      allWordpressPost(sort: {fields: [date]}) {
        edges {
          node {
            title
            excerpt
            link
            date(formatString: "MM-DD-YYYY")
            type
            categories {
              name
              link
              wordpress_id
            }
            licenses_and_approvals
            author {
              name
            }
            compliance_services
          }
        }
      }
        allWordpressWpRegulatoryInsight(sort: {fields: [date]}) {
        edges {
          node {
            title
            excerpt
            link
            date(formatString: "MM-DD-YYYY")
            type
            categories {
              name
              link
              wordpress_id
            }
            licenses_and_approvals
            compliance_services
            content
          }
        }
      }
      allWordpressWpRegulatoryUpdates(sort: {fields: [date]}) {
        edges {
          node {
            title
            excerpt
            link
            date(formatString: "MM-DD-YYYY")
            type
            categories {
              name
              link
              wordpress_id
            }
            licenses_and_approvals
            compliance_services
            
            acf {
              date_of_implementation
              draft_or_gazette
              impacted_products
              reference
              reference_regulation
              summary
            }
            author {
              name
            }
          }
        }
      }
  }`);

    let newsArr = [];
    let regulatoryArr = [];
    let insightArr = [];

    if(category.taxonomy.name === "Categories"){
        newsArr = q.allWordpressPost.edges.filter(e => e.node.categories.filter(e2 => e2.wordpress_id === category.wordpress_id).length);
        regulatoryArr = q.allWordpressWpRegulatoryUpdates.edges.filter(e => e.node.categories.filter(e2 => e2.wordpress_id === category.wordpress_id).length);
        insightArr = q.allWordpressWpRegulatoryInsight.edges.filter(e => e.node.categories.filter(e2 => e2.wordpress_id === category.wordpress_id).length);
    }
    else if(category.taxonomy.name === "Compliance Services"){
        newsArr = q.allWordpressPost.edges.filter(e => e.node.compliance_services.includes(category.wordpress_id));
        regulatoryArr = q.allWordpressWpRegulatoryUpdates.edges.filter(e => e.node.compliance_services.includes(category.wordpress_id));
        insightArr = q.allWordpressWpRegulatoryInsight.edges.filter(e => e.node.compliance_services.includes(category.wordpress_id));
    }
    else if(category.taxonomy.name === "Licenses and Approvals"){
        newsArr = q.allWordpressPost.edges.filter(e => e.node.licenses_and_approvals.includes(category.wordpress_id));
        regulatoryArr = q.allWordpressWpRegulatoryUpdates.edges.filter(e => e.node.licenses_and_approvals.includes(category.wordpress_id));
        insightArr = q.allWordpressWpRegulatoryInsight.edges.filter(e => e.node.licenses_and_approvals.includes(category.wordpress_id));
    }

    let yoast_head = globe.domainReplace(category.yoast_head)
    let temp = yoast_head.match(/<script.+?<\/script>/g)[0] || ''
    let schema = temp !== undefined && temp !== null && temp.length > 70 ? temp.substring(62, temp.length-9) : {}


    return (
        <>

            <Helmet>

                <html lang="en" />
                <title>{category.name}  | Metrify India</title>

                {category.yoast_head.match(/name="description"/g) === null ? <meta name="description" content={category.name +" - Metrify India"}/> : null}
                { ReactHtmlParser(yoast_head) }
                <meta property="og:image" content={q.site.siteMetadata.siteUrl + images.ogLogo} />

                <script type="application/ld+json">
                    {schema}
                </script>

            </Helmet>

            <Header/>
            <Menu/>

            <div className="section-title pt-5">
                <h1 className="section-title-heading fsm-theme-heading" dangerouslySetInnerHTML={{__html: category.name}} />
            </div>


            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <MixPostGrid data={{newsArr:newsArr,regulatoryArr:regulatoryArr,insightArr:insightArr}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"py-4 text-center"}>
                <ReadMoreBtn type={'link'} text={"View All Categories"} url={"/categories/"}/>
            </div>
            <Footer/>
            <SharePopupModel name={"shareModal"} />
        </>
    )
}
